.toggle > .toggle-content.toggle-content {
  display: revert;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.toggle > .toggle-content.toggle-content.active {
  /* max-height: 200px; */
  max-height: 550px;
}
