/* Primary Button */
.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #333333;
  --bs-btn-hover-border-color: #333333;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4d4d4d;
  --bs-btn-active-border-color: #4d4d4d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #000000;
  --bs-btn-disabled-border-color: #000000;
}

/* Secondary Button */
.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e45d2d; /* secondary */
  --bs-btn-border-color: #993613; /* secondaryBorder */
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #cc4f29;
  --bs-btn-hover-border-color: #883010;
  --bs-btn-focus-shadow-rgb: 228, 93, 45;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #993613;
  --bs-btn-active-border-color: #883010;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #e45d2d;
  --bs-btn-disabled-border-color: #993613;
}

/* Tertiary Button */
.btn-tertiary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0ca04c; /* tertiary */
  --bs-btn-border-color: #097136; /* tertiaryBorder */
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0b8d42;
  --bs-btn-hover-border-color: #075c28;
  --bs-btn-focus-shadow-rgb: 12, 160, 76;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #097136;
  --bs-btn-active-border-color: #075c28;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0ca04c;
  --bs-btn-disabled-border-color: #097136;
}

/* Quaternary Button */
.btn-quaternary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #146ebc; /* quaternary */
  --bs-btn-border-color: #09375e; /* quaternaryBorder */
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #125fa7;
  --bs-btn-hover-border-color: #082d4d;
  --bs-btn-focus-shadow-rgb: 20, 110, 188;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #09375e;
  --bs-btn-active-border-color: #082d4d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #146ebc;
  --bs-btn-disabled-border-color: #09375e;
}

/* Success Button */
.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #41b341; /* success */
  --bs-btn-border-color: #41b341; /* successBorder */
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3aa03a;
  --bs-btn-hover-border-color: #369836;
  --bs-btn-focus-shadow-rgb: 65, 179, 65;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #369836;
  --bs-btn-active-border-color: #319031;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #41b341;
  --bs-btn-disabled-border-color: #41b341;
}

/* Warning Button */
.btn-warning {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #f6a821; /* warning */
  --bs-btn-border-color: #f6a821; /* warningBorder */
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #e3981e;
  --bs-btn-hover-border-color: #d28a1b;
  --bs-btn-focus-shadow-rgb: 246, 168, 33;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #d28a1b;
  --bs-btn-active-border-color: #c17919;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #f6a821;
  --bs-btn-disabled-border-color: #f6a821;
}

/* Error Button */
.btn-error {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #ee1515; /* error */
  --bs-btn-border-color: #ee1515; /* errorBorder */
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #cc1313;
  --bs-btn-hover-border-color: #bb1111;
  --bs-btn-focus-shadow-rgb: 238, 21, 21;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #bb1111;
  --bs-btn-active-border-color: #aa0f0f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #ee1515;
  --bs-btn-disabled-border-color: #ee1515;
}

/* Link Button */
.btn-link {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #72b6ff; /* link */
  --bs-btn-border-color: #007bff; /* linkBorder */
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #61a7e6;
  --bs-btn-hover-border-color: #006fdd;
  --bs-btn-focus-shadow-rgb: 114, 182, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #006fdd;
  --bs-btn-active-border-color: #005ebf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #72b6ff;
  --bs-btn-disabled-border-color: #007bff;
}

/* Info Button */
.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #708090; /* info */
  --bs-btn-border-color: #708090; /* infoBorder */
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #627078;
  --bs-btn-hover-border-color: #556067;
  --bs-btn-focus-shadow-rgb: 112, 128, 144;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #556067;
  --bs-btn-active-border-color: #4a535b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #708090;
  --bs-btn-disabled-border-color: #708090;
}
